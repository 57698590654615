export default {
  en: {
    "App": {
      "menu": {
        "menuTitle": "Navigator",
        "Home": "Home",
        "Data Sources": "Data Sources",
        "Add Data Sources": "Add Data Sources",
        "View Data Sources": "View Data Sources",
        "Facility Registry": "Facility Registry",
        "Search": "Search",
        "Search Facility": "Search Facility",
        "Search Jurisdiction": "Search Jurisdiction",
        "Search Organization": "Search Organization",
        "Search Service": "Search Service",
        "Search Facility Add Request": "Search Facility Add Request",
        "Add Jurisdiction": "Add Jurisdiction",
        "Add Facility": "Add Facility",
        "Add Organization": "Add Organization",
        "Add Service": "Add Service",
        "Requests": "Requests",
        "Request Addition of New Facility": "Request Addition of New Facility",
        "Request Update of Facility Details": "Request Update of Facility Details",
        "Facility Reconciliation": "Facility Reconciliation",
        "Create and Manage Data Source Pair": "Create and Manage Data Source Pair",
        "Reconcile": "Reconcile",
        "Reconciliation Status": "Reconciliation Status",
        "Pair Side to Side View": "Pair Side to Side View",
        "Map": "Map",
        "configure System": "configure System",
        "Account": "Account",
        "Add User": "Add User",
        "Users List": "Users List",
        "Roles Management": "Roles Management",
        "Apps": "Apps"
      },
      "gofr-codesystem": {},
      "gofr-search": {},
      "gofr-search-term": {},
      "gofr-search-reference-term": {},
      "gofr-search-code": {},
      "gofr-array": {},
      "gofr-complex-card": {},
      "gofr-questionnaire": {},
      "gofr-questionnaire-section": {},
      "gofr-questionnaire-group": {},
      "gofr-resource": {},
      "fhir-string": {},
      "fhir-resources-texts": {
        "Request Addition of New Facility": "Request Addition of New Facility",
        "Basic Details ": "Basic Details ",
        " uncategorized details": " uncategorized details",
        "Facility Name": "Facility Name",
        "Alternative/Nick Names": "Alternative/Nick Names",
        "Description": "Description",
        "Status": "Status",
        "Facility Types": "Facility Types",
        "Physical Type": "Physical Type",
        "Parent": "Parent",
        "Request Status": "Request Status",
        "Identifiers": "Identifiers",
        "Identifiers for the facility": "Identifiers for the facility",
        "Identifier": "Identifier",
        "System": "System",
        "ID Number": "ID Number",
        "ID Type": "ID Type",
        "Geo-Coordinates": "Geo-Coordinates",
        "Geo-coordinates for the facility": "Geo-coordinates for the facility",
        "Longitude": "Longitude",
        "Latitude": "Latitude",
        "Altitude": "Altitude",
        "Contacts": "Contacts",
        "Contacts for the facility": "Contacts for the facility",
        "Contact Type": "Contact Type",
        "Value": "Value",
        "Contact Use": "Contact Use",
        "Address": "Address",
        "Address Use": "Address Use",
        "Address Type": "Address Type",
        "Street Address": "Street Address",
        "City": "City",
        "District": "District",
        "State": "State",
        "Postal Code": "Postal Code",
        "Country": "Country",
        "Hours of operation": "Hours of operation",
        "Facility availability": "Facility availability",
        "Availability": "Availability",
        "Days of week": "Days of week",
        "All day": "All day",
        "Opening time": "Opening time",
        "Closing time": "Closing time",
        "Add Organization": "Add Organization",
        " Basic Details": " Basic Details",
        "Name": "Name",
        "Type": "Type",
        "Attach To Hierarchy": "Attach To Hierarchy",
        "Parent Type": "Parent Type",
        "Add Facility": "Add Facility",
        "Managing Organization": "Managing Organization",
        "Office Contacts": "Office Contacts",
        "Contact Person": "Contact Person",
        "Purpose": "Purpose",
        "Given Name": "Given Name",
        "Family Name": "Family Name",
        "Telecom Use": "Telecom Use",
        "Telecom System": "Telecom System",
        "Mobile Phone": "Mobile Phone",
        "Work Email": "Work Email",
        "Organization": "Organization",
        "Organization Names": "Organization Names",
        "Organization Type": "Organization Type",
        "Add Jurisdiction": "Add Jurisdiction",
        "Jurisdiction Name": "Jurisdiction Name",
        "Jurisdiction Type": "Jurisdiction Type",
        "Oranization Names": "Oranization Names",
        "Oranization Type": "Oranization Type",
        "Geo-coordinates for the jurisdiction": "Geo-coordinates for the jurisdiction",
        "Boundaries": "Boundaries",
        "Boundary": "Boundary",
        "Request Updating Facility": "Request Updating Facility",
        "Exclude From Public View Facility With Below Values": "Exclude From Public View Facility With Below Values",
        "Update Organization": "Update Organization",
        "Facility Details": "Facility Details",
        "Update Jurisdiction": "Update Jurisdiction",
        "Add/Update Healthcare Service": "Add/Update Healthcare Service",
        "Create Change Request": "Create Change Request",
        "Facilities": "Facilities",
        "Exclude Facilities Of": "Exclude Facilities Of",
        "Use": "Use",
        "mCSD Organization for Facilities": "mCSD Organization for Facilities",
        "Basic Details": "Basic Details",
        "": "",
        "Usage": "Usage",
        "Family": "Family",
        "Given": "Given",
        "Facility Type": "Facility Type",
        "Facility Physical Type": "Facility Physical Type",
        "Longitute": "Longitute",
        "GOFR Facility Update Request": "GOFR Facility Update Request",
        "Affected Resource": "Affected Resource",
        "Nickname": "Nickname",
        "Facility Geo-Coordinates": "Facility Geo-Coordinates",
        "Co-ordinates": "Co-ordinates",
        "Facility Identifiers": "Facility Identifiers",
        "Facility Address": "Facility Address",
        "Line": "Line",
        "Location": "Location",
        "Telecom": "Telecom",
        "Open all day": "Open all day",
        "Time that the facility is open": "Time that the facility is open",
        "Time that the facility closes": "Time that the facility closes",
        "mCSD Organization": "mCSD Organization",
        "mCSD Organization for Jurisdictions": "mCSD Organization for Jurisdictions",
        "Contact Details": "Contact Details",
        "Address, email, phone numbers": "Address, email, phone numbers",
        "Business hours": "Business hours",
        "Organizations Managing This Facility": "Organizations Managing This Facility",
        "Edit": "Edit",
        "Actions": "Actions",
        "GOFR Facility Add Request": "GOFR Facility Add Request",
        "Active": "Active",
        "mCSD HealthcareService": "mCSD HealthcareService",
        "Facility": "Facility",
        "Details": "Details",
        "Healthcare service details": "Healthcare service details",
        "Broad Category of Service": "Broad Category of Service",
        "Type of Service": "Type of Service",
        "Specialty": "Specialty",
        "Comment about this service": "Comment about this service",
        "Service Provision Condition": "Service Provision Condition",
        "Eligibility": "Eligibility",
        "Programs": "Programs",
        "Communication Language": "Communication Language",
        "Referral Methods": "Referral Methods",
        "Appointment Required": "Appointment Required",
        "Service Time Availability": "Service Time Availability",
        "Available all day": "Available all day",
        "Opening Time": "Opening Time",
        "Closing Time": "Closing Time",
        "Service Unavailability": "Service Unavailability",
        "Dates Unavailable": "Dates Unavailable",
        "Start Date": "Start Date",
        "End Date": "End Date",
        "Facilities Service Is Offered": "Facilities Service Is Offered",
        "Facility Service Is Provided": "Facility Service Is Provided",
        "HealthcareService": "HealthcareService",
        "Jurisdictions": "Jurisdictions",
        "Jurisdiction details": "Jurisdiction details",
        "Location Type": "Location Type",
        "Organizations Managing This Jurisdiction": "Organizations Managing This Jurisdiction"
      },
      "hardcoded-texts": {
        "1": "1",
        "2": "2",
        "3": "3",
        "4": "4",
        "5": "5",
        "6": "6",
        "7": "7",
        "Display": "Display",
        "Definition": "Definition",
        "Select App to Uninstall": "Select App to Uninstall",
        "Uninstall": "Uninstall",
        "Install": "Install",
        "Install New GOFR App": "Install New GOFR App",
        "Cancel": "Cancel",
        "Save": "Save",
        "Edit": "Edit",
        "Delete": "Delete",
        "Editing": "Editing",
        "Yes": "Yes",
        "Search": "Search",
        "Add": "Add",
        "Update": "Update",
        "Export": "Export",
        "Name": "Name",
        "Host": "Host",
        "Source Type": "Source Type",
        "User Name": "User Name",
        "Password": "Password",
        "TableText": "Rows per page",
        "Sections": "Sections",
        "Section": "Section",
        "Approve": "Approve",
        "Reject": "Reject",
        "Send Update Request": "Send Update Request",
        "Currency": "Currency",
        "This will delete the datasource": "This will delete the datasource",
        "from the database": "from the database",
        "Below data source pairs (mapping) will also be deleted": "Below data source pairs (mapping) will also be deleted",
        "no_src_pair_for_src": "There is no any data source pair (mapping) associated with this data source",
        "Do you want to proceed and delete": "Do you want to proceed and delete",
        "Sharing": "Sharing",
        "Share": "Share",
        "limit_share": "Limiting Sharing to",
        "About this page": "About this page",
        "This page let you visualize various data sets you have added into the app as well as synchronize remote servers with the app": "This page let you visualize various data sets you have added into the app as well as synchronize remote servers with the app",
        "You may come back to this page and add more sources at any time": "You may come back to this page and add more sources at any time",
        "You may proceed to the 'Data Source Pair' page after you have added atleast two data sources": "You may proceed to the 'Data Source Pair' page after you have added atleast two data sources",
        "Use Sync (Update) to pull updated records from the remote server and update the app": "Use Sync (Update) to pull updated records from the remote server and update the app",
        "Use Force Full Sync to fetch all data from the remote server and update the app": "Use Force Full Sync to fetch all data from the remote server and update the app",
        "Help": "Help",
        "Remote Sources": "Remote Sources",
        "Force Full Sync": "Force Full Sync",
        "sync_update": "Sync (Update)",
        "Uploaded Sources": "Uploaded Sources",
        "Export Original CSV": "Export Original CSV",
        "Detailed View": "Detailed View",
        "Add New Remote Source": "Add New Remote Source",
        "Source Name": "Source Name",
        "Base URL": "Base URL",
        "Share this dataset with all other users that are on the same org unit as you": "Share this dataset with all other users that are on the same org unit as you",
        "Share with other users of the same org unit as yours": "Share with other users of the same org unit as yours",
        "Share with all other users": "Share with all other users",
        "Limit orgs sharing by user orgid": "Limit orgs sharing by user orgid",
        "if activated, other users will see locations (including location children) that has the same location id as their location id": "if activated, other users will see locations (including location children) that has the same location id as their location id",
        "Add Blank Source": "Add Blank Source",
        "Total": "Total",
        "Facilities": "Facilities",
        "Facility": "Facility",
        "Jurisdictions": "Jurisdictions",
        "Created": "Created",
        "Limited by User Location": "Limited by User Location",
        "Shared To All": "Shared To All",
        "Shared Users": "Shared Users",
        "Permissions": "Permissions",
        "View": "View",
        "Organizations": "Organizations",
        "Healthcare Services": "Healthcare Services",
        "Detailed View Of": "Detailed View Of",
        "Data Source": "Data Source",
        "Back to Datasources": "Back to Datasources",
        "Datasources Contributing To This Data Source": "Datasources Contributing To This Data Source",
        "Update Permissions": "Update Permissions",
        "View Data": "View Data",
        "Information": "Information",
        "Data Upload was not successful,review below invalid rows in your CSV": "Data Upload was not successful,review below invalid rows in your CSV",
        "You are about to upload CSV data into the app, click proceed to upload": "You are about to upload CSV data into the app, click proceed to upload",
        "Warning": "Warning",
        "Proceed": "Proceed",
        "Ok": "Ok",
        "Upload CSV": "Upload CSV",
        "Map Headers": "Map Headers",
        "Upload CSV (utf-8 only)": "Upload CSV (utf-8 only)",
        "Select a CSV file and upload": "Select a CSV file and upload",
        "Continue": "Continue",
        "Map an appropriate CSV header against those on the app": "Map an appropriate CSV header against those on the app",
        "Code": "Code",
        "Select": "Select",
        "Latitude": "Latitude",
        "Longitude": "Longitude",
        "Level": "Level",
        "Add More Level": "Add More Level",
        "Upload": "Upload",
        "This page let you load data from various sources into the app for reconciliation": "This page let you load data from various sources into the app for reconciliation",
        "Select to add remote source if you have a DHIS2 or FHIR server that you want to use its data on this app": "Select to add remote source if you have a DHIS2 or FHIR server that you want to use its data on this app",
        "Select Upload CSV if you have a CSV file and want to upload its data on the app": "Select Upload CSV if you have a CSV file and want to upload its data on the app",
        "The system requires CSV data to have atleast 2 levels above facility": "The system requires CSV data to have atleast 2 levels above facility",
        "Level 1 is the highest level on the hierarchy i.e Country": "Level 1 is the highest level on the hierarchy i.e Country",
        "Base URL under remote sources section refer to the URL i.e": "Base URL under remote sources section refer to the URL i.e",
        "and not": "and not",
        "Same applies to DHIS2 base URL": "Same applies to DHIS2 base URL",
        "Choose way to add data source": "Choose way to add data source",
        "Blank Source": "Blank Source",
        "Remote Source": "Remote Source",
        "Are you sure you want to delete this data source pair": "Are you sure you want to delete this data source pair",
        "Pair creation limit": "Pair creation limit",
        "You cant create more pairs as this account is limited to one pair only at a time": "You cant create more pairs as this account is limited to one pair only at a time",
        "Data sources has different level counts, please map Levels to proceed": "Data sources has different level counts, please map Levels to proceed",
        "Mapping": "Mapping",
        "This page let you choose a pair of data sources to use for reconciliation": "This page let you choose a pair of data sources to use for reconciliation",
        "Source 1 is the source while source 2 is the target": "Source 1 is the source while source 2 is the target",
        "Sharing Pair": "Sharing Pair",
        "create_choose_pair": "Create/Choose a pair of data sources to use for reconciliation. Source 1 is the source while source 2 is the target",
        "Create Data Source Pair": "Create Data Source Pair",
        "Pair Name": "Pair Name",
        "Reset": "Reset",
        "Existing Data Source Pairs": "Existing Data Source Pairs",
        "Pair": "Pair",
        "Activate Pair": "Activate Pair",
        "Authentication Failed": "Authentication Failed",
        "Login": "Login",
        "Signup": "Signup",
        "Status": "Status",
        "Source 2": "Source 2",
        "Source 1": "Source 1",
        "Active Reconciliation Pair": "Active Reconciliation Pair",
        "Active Partition Stats": "Active Partition Stats",
        "Facility Registry Datasource": "Facility Registry Datasource",
        "Active Partition": "Active Partition",
        "Logout": "Logout",
        "Services": "Services",
        "Request Addition of New Facility": "Request Addition of New Facility",
        "Request Update of Facility Details": "Request Update of Facility Details",
        "Disabling Single Data Source Limit": "Disabling Single Data Source Limit",
        "Disabling limiting reconciliation to be done against one choosen data source will also disable the single data source limit, click OK to proceed": "Disabling limiting reconciliation to be done against one choosen data source will also disable the single data source limit, click OK to proceed",
        "DHIS2 superuser role that can be an administrator of GOFR": "DHIS2 superuser role that can be an administrator of GOFR",
        "Superuser Role Name": "Superuser Role Name",
        "Select datasource to fix source 2": "Select datasource to fix source 2",
        "This lists only those datasets that have been shared to all users": "This lists only those datasets that have been shared to all users",
        "System Configurations": "System Configurations",
        "User Configurations": "User Configurations",
        "Apply user defined headers when reconciling": "Apply user defined headers when reconciling",
        "Admin Configurations": "Admin Configurations",
        "Perform match based on parent constraint": "Perform match based on parent constraint",
        "Automatch By ID": "Automatch By ID",
        "Automatch By Name (when parents differ)": "Automatch By Name (when parents differ)",
        "Choose ways datasets can be added": "Choose ways datasets can be added",
        "CSV Upload": "CSV Upload",
        "Remote Servers Sync": "Remote Servers Sync",
        "Blank Datasource": "Blank Datasource",
        "Allow non admin users to share datasets will all users": "Allow non admin users to share datasets will all users",
        "Select a data source to serve as Source 2 for all reconciliation": "Select a data source to serve as Source 2 for all reconciliation",
        "This will limit users to perform reconciliations against the chosen data source": "This will limit users to perform reconciliations against the chosen data source",
        "Source2 Limited To": "Source2 Limited To",
        "Change dataset": "Change dataset",
        "Single data source per user": "Single data source per user",
        "Single data source pair per org unit": "Single data source pair per org unit",
        "GOFR Authentication": "GOFR Authentication",
        "Disable Authentication": "Disable Authentication",
        "External Authentication Method": "External Authentication Method",
        "Pull org units": "Pull org units",
        "Share orgs with other users": "Share orgs with other users",
        "Dataset Name": "Dataset Name",
        "Start Pulling": "Start Pulling",
        "Self Registration": "Self Registration",
        "Enable self registration": "Enable self registration",
        "Requires Admin Approval Of Self Registration": "Requires Admin Approval Of Self Registration",
        "Public Access": "Public Access",
        "Enable public access": "Enable public access",
        "Public Datasource": "Public Datasource",
        "Filter Access": "Filter Access",
        "Cron Jobs": "Cron Jobs",
        "Autosync Below Remote Datasets": "Autosync Below Remote Datasets",
        "Cron Time": "Cron Time",
        "SMTP Configuration For Email Notifications": "SMTP Configuration For Email Notifications",
        "SMTP Host": "SMTP Host",
        "SMTP Port": "SMTP Port",
        "SMTP Username": "SMTP Username",
        "SMTP Password": "SMTP Password",
        "SMTP Secured": "SMTP Secured",
        "Enable Endpoint Notification when reconciliation is done": "Enable Endpoint Notification when reconciliation is done",
        "End point to send notification when reconciliation is done": "End point to send notification when reconciliation is done",
        "End point URL": "End point URL",
        "End point Username": "End point Username",
        "End point Password": "End point Password",
        "Reconcile": "Reconcile",
        "Wait for upload to finish": "Wait for upload to finish",
        "This page let you view what you have uploaded or synchronized from a remote server": "This page let you view what you have uploaded or synchronized from a remote server",
        "Use the tree to filter grid data": "Use the tree to filter grid data",
        "Edit Location": "Edit Location",
        "Parent": "Parent",
        "Source 1 Data Tree": "Source 1 Data Tree",
        "Source 2 Data Tree": "Source 2 Data Tree",
        "Source 1 Data Grid": "Source 1 Data Grid",
        "FHIR Export": "FHIR Export",
        "CSV Export": "CSV Export",
        "Source2 Unmatched": "Source2 Unmatched",
        "Source1 Unmatched": "Source1 Unmatched",
        "Matched": "Matched",
        "Select file type to download": "Select file type to download",
        "Source 2 Location": "Source 2 Location",
        "ID": "ID",
        "Geo Dist (Miles)": "Geo Dist (Miles)",
        "Score": "Score",
        "Comment": "Comment",
        "Add comment for this flag if any": "Add comment for this flag if any",
        "Flag Comment": "Flag Comment",
        "Matching": "Matching",
        "Parents": "Parents",
        "Flag": "Flag",
        "Mark the selected item as a match to be reviewed": "Mark the selected item as a match to be reviewed",
        "Save Match": "Save Match",
        "Save the selected item as a match": "Save the selected item as a match",
        "No Match": "No Match",
        "Save this Source 1 location as having no match": "Save this Source 1 location as having no match",
        "Ignore": "Ignore",
        "Mark this source 1 location as being ignored": "Mark this source 1 location as being ignored",
        "Show All Suggestions": "Show All Suggestions",
        "See all possible choices ignoring the score": "See all possible choices ignoring the score",
        "Back": "Back",
        "Return without saving": "Return without saving",
        "Reconciling": "Reconciling",
        "Recalculate Scores": "Recalculate Scores",
        "Saving matches for": "Saving matches for",
        "This page let you map source 1 data with those in source2": "This page let you map source 1 data with those in source2",
        "Source 1 refer to the data source name selected as source 1 under data source pair section": "Source 1 refer to the data source name selected as source 1 under data source pair section",
        "Source 2 refer to the data source name selected as source 2 under data source pair section": "Source 2 refer to the data source name selected as source 2 under data source pair section",
        "After breaking a match, you will need to recalculate scores for the app to load potential matches of the broken location": "After breaking a match, you will need to recalculate scores for the app to load potential matches of the broken location",
        "FLAGGED Locations are the locations that will need to be reviewed before they are saved as matches": "FLAGGED Locations are the locations that will need to be reviewed before they are saved as matches",
        "NO MATCHES - these are locations that do not matches anything from source 2": "NO MATCHES - these are locations that do not matches anything from source 2",
        "Source 1 Reconciliation Status": "Source 1 Reconciliation Status",
        "Unmatched": "Unmatched",
        "Flagged": "Flagged",
        "Source 1 Unmatched": "Source 1 Unmatched",
        "Source 2 Unmatched": "Source 2 Unmatched",
        "Source 2 Reconciliation Status": "Source 2 Reconciliation Status",
        "Not in Source 1": "Not in Source 1",
        "MATCHED": "MATCHED",
        "NO MATCH": "NO MATCH",
        "IGNORED": "IGNORED",
        "FLAGGED": "FLAGGED",
        "Break Match": "Break Match",
        "Break Ignore": "Break Ignore",
        "Confirm Match": "Confirm Match",
        "Release": "Release",
        "Proceed to": "Proceed to",
        "Reconciliation Status": "Reconciliation Status",
        "Add New User": "Add New User",
        "First Name": "First Name",
        "Middle Names": "Middle Names",
        "Surname": "Surname",
        "Phone": "Phone",
        "Email": "Email",
        "Re-type Password": "Re-type Password",
        "Role": "Role",
        "This page let you visualize the mapping status for various levels": "This page let you visualize the mapping status for various levels",
        "Right boxes shows reconciliation status for a selected level": "Right boxes shows reconciliation status for a selected level",
        "Left boxes shows reconciliation status for a all levels": "Left boxes shows reconciliation status for a all levels",
        "The search box let you search locations for a selected level only": "The search box let you search locations for a selected level only",
        "Only": "Only",
        "Source 1 Matched": "Source 1 Matched",
        "Source 1 No Match": "",
        "Source 1 Flagged": "Source 1 Flagged",
        "Source 2 Matched": "Source 2 Matched",
        "Source 2 Flagged": "Source 2 Flagged",
        "Mark Reconciliation As Done": "Mark Reconciliation As Done",
        "Mark Reconciliation As UnDone": "Mark Reconciliation As UnDone",
        "Source 1 Not Mapped": "Source 1 Not Mapped",
        "Source 1 NO MATCH": "Source 1 IGNORED",
        "Create Account": "Create Account",
        "Clear": "Clear",
        "Back To Login": "Back To Login",
        "Change": "Change",
        "Old Password": "Old Password",
        "Change Password": "Change Password",
        "Tasks assigned to role": "Tasks assigned to role",
        "Select role to edit tasks": "Select role to edit tasks",
        "Select Role": "Select Role",
        "User": "User",
        "Approve/Reject": "Approve/Reject",
        "Deactivate": "Deactivate",
        "Activate": "Activate",
        "Reset Password": "Reset Password",
        "Other Name": "Other Name",
        "Installed Apps": "Installed Apps",
        "Advanced Options": "Advanced Options",
        "Last Sync": "Last Sync",
        "Owner": "Owner",
        "Shared To": "Shared To",
        "Created Time": "Created Time",
        "Action": "Action",
        "Source1 Location": "Source1 Location",
        "Source1 ID": "Source1 ID",
        "Source2 Location": "Source2 Location",
        "Source2 ID": "Source2 ID",
        "Match Comment": "Match Comment",
        "Location": "Location",
        "Users List": "Users List",
        "Last Updated Time": "Last Updated Time",
        "Enabled": "Enabled"
      },
      "title": "Manage",
      "site": "Demo"
    }
  }
}